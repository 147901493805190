export const setLoading = (value) => ({
    type: 'SET_LOADING',
    value,
});

export const setGlobalWarning = (warning) => {
    return (
        {
            type: 'SET_GLOBAL_WARNING',
            warning
        });
}

export const setGlobalSuccess = (success) => ({
    type: 'SET_GLOBAL_SUCCESS',
    success,
})

export const setGlobalError = (error) => ({
    type: 'SET_GLOBAL_ERROR',
    error
});


export const setFlickrFptwebsite = (images) => ({
    type: 'SET_FLICKR_FPTWEBSITE',
    images
});


export const loadFlickrFptwebsite = () => {
    return (dispatch, getState) => {

        const defaultImages = [
            { image: 'https://live.staticflickr.com/65535/49501957977_941ca58e9f_z.jpg' },
            { image: 'https://live.staticflickr.com/65535/49501733536_3fbd9d7f2b_z.jpg' },
            { image: 'https://live.staticflickr.com/65535/49501736871_162c61af0e_z.jpg' },
            { image: 'https://live.staticflickr.com/65535/49501940092_1e92bfb624_z.jpg' },
            { image: 'https://live.staticflickr.com/65535/49501723296_0b407663e5_z.jpg' },
            { image: 'https://live.staticflickr.com/65535/49501224348_63f99e6f33_z.jpg' }
        ]

        dispatch(setLoading(true));
        const flickrTag = "fptwebsite";
        const flickrUrl = "https://www.flickr.com/services/rest/?method=flickr.photos.search&tags=" + flickrTag + "&api_key=e117905dbf4b792551c6d094ddd8fc41&user_id=140184022@N05&extras=url_z&format=json&nojsoncallback=true";

        fetch(flickrUrl)
            .then(response => response.json())
            .then(data => {
                const flickrImages = data.photos.photo.map((item, index) => ({
                    image: item.url_z
                }));
                dispatch(setFlickrFptwebsite(flickrImages));
                dispatch(setLoading(false));
            })
            .catch(error => {
                dispatch(setLoading(false));
                setFlickrFptwebsite(defaultImages)
            });
    }
}